// @refresh reload
import { Suspense } from "solid-js";
import { useAssets } from "solid-js/web";
import {
  A,
  Body,
  ErrorBoundary,
  FileRoutes,
  Head,
  Html, Link,
  Meta,
  Routes,
  Scripts,
  Title,
} from "solid-start";
import { css, renderSheets, StyleRegistry, type StyleData } from "solid-styled";

export default function Root() {
  const sheets: StyleData[] = [];
  useAssets(() => renderSheets(sheets));

  css`
    * {
      padding: 0;
      margin: 0;
    }
    
    @font-face {
      font-family: 'dotty';
      src: url('/Bazaronite.ttf');
    }

    body {
      background-color: black;
      color: white;
      height: 100%;
    }
    
    html {
      height: 100%;
    }
  `
  return (
    <StyleRegistry styles={sheets}>
      <Html lang="en" use:solid-styled>
        <Head>
          <Meta charset="utf-8" />
          <Meta name="viewport" content="width=device-width, initial-scale=1" />
          <Link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>
          <Link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
          <Link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
          <Link rel="manifest" href="/site.webmanifest"/>
          <Link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5"/>
          <Meta name="theme-color" content="#ffffff"/>
        </Head>
        <Body use:solid-styled>
          <Suspense>
            <ErrorBoundary>
              <Routes>
                <FileRoutes />
              </Routes>
            </ErrorBoundary>
          </Suspense>
          <Scripts />
        </Body>
      </Html>
    </StyleRegistry>
  );
}
